import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { gql, useQuery } from '@apollo/client';
import Loading from './shared/Loading';

const MessageTextEditor = ({ editorRef, value, onChange, onDrop }) => {
    import('./tinymce-mention');

    const { data, loading } = useQuery(USERS_QUERY);

    if (loading) return <Loading />;

    const source = data?.users.map(user => (
        { id: user.id, name: user.displayName }
    ));

    return (
        <Editor
            onInit={(_evt, editor) => editorRef.current = editor}
            value={value}
            onEditorChange={onChange}
            onDrop={onDrop}
            init={{
                menubar: false,
                statusbar: false,
                contextmenu: false,
                nonbreaking_force_tab: true,
                paste_block_drop: true,
                placeholder: 'Type your message...',
                link_default_target: '_blank',
                link_target_list: [
                    { text: 'New window', value: '_blank' },
                    { text: 'Current window', value: '_self' },
                ],
                extended_valid_elements : 'a[href|target=_blank]',
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                    'nonbreaking', 'autoresize', 'mention'
                ],
                toolbar: 'undo redo | bold link | bullist numlist',
                mentions: {
                    source,
                    render: function(item) {
                        return '<li class="dropdown-item small hoverable">' +
                               '<a class="text-dark" href="javascript:;"><span>' + item.name +
                               '</span></a></li>';
                    },
                },
            }}
        />
    );
}

const USERS_QUERY = gql`
    query Users {
        users {
            id
            displayName
        }
    }
`;

export default MessageTextEditor;