import { gql } from '@apollo/client';
import {
    CASE_FIELDS,
    CASE_NETWORK_FIELDS,
    FEEDBACK_FIELDS,
    TEMPLATE_FIELDS,
    CASE_DETAILS,
    PROFILE_INFORMATION,
    REPORT_SUMMARIES,
    USER_FIELDS,
    CASE_NETWORK_INDEX_FIELDS,
    SCREENSHOT_FIELDS,
    WORK_SESSION_FIELDS,
    CASE_CHAT_FIELDS,
    AGENT_LOG_FIELDS,
    FEEDBACK_FIELDS_V2,
} from './fragments';

export const CASE_QUERY = gql`
    ${CASE_FIELDS}
    ${USER_FIELDS}
    query Case($id: ID!) {
        case(id: $id) {
            ...CaseFields
        }
        currentUser {
            ...UserFields
        }
    }
`;

export const CASE_DETAILS_QUERY = gql`
    ${CASE_DETAILS}
    query Case($id: ID!) {
        case(id: $id) {
            ...CaseDetails
        }
    }
`;

export const PROFILE_INFORMATION_QUERY = gql`
    ${PROFILE_INFORMATION}
    query Case($id: ID!) {
        case(id: $id) {
            ...ProfileInformation
        }
    }
`;

export const REPORT_SUMMARIES_QUERY = gql`
    ${REPORT_SUMMARIES}
    query Case($id: ID!) {
        case(id: $id) {
            ...ReportSummaries
        }
    }
`;

export const CASE_NETWORK_QUERY = gql`
    ${CASE_NETWORK_FIELDS}
    query CaseNetwork($id: ID!, $caseId: ID!) {
        caseNetwork(id: $id, caseId: $caseId) {
            ...CaseNetworkFields
        }
        case(id: $caseId) {
            id
            client {
                id
            }
            name
            isEditable
            canEditImprovementFeedback
            isProgressive
            isIq
            isFindingsEnabled
        }
    }
`;

export const CASE_NETWORK_CONFIG_QUERY = gql`
    query CaseNetwork($id: ID!, $caseId: ID!) {
        caseNetwork(id: $id, caseId: $caseId) {
            id
            hasProfileLink
            hasProfilePhoto
            hasAttachments
            hasScreenshots
            hasFriendsFollowers
            hasActivitySummary
        }
    }
`;

export const CASE_FEEDBACK_QUERY = gql`
    ${FEEDBACK_FIELDS}
    query Case($id: ID!) {
        case(id: $id) {
            id
            feedbacks {
                ...FeedbackFields
            }
        }
    }
`;

export const TEMPLATES_QUERY = gql`
    ${TEMPLATE_FIELDS}
    query TinymceTemplates($className: String!, $id: ID!, $clientId: ID!, $section: String!, $field: String!) {
        tinymceTemplates(clientId: $clientId, section: $section, field: $field) {
            ...TemplateFields
        }
        tinymceTemplateReplaceValues(className: $className, recordId: $id)
    }
`;

export const PRODUCTS_QUERY = gql`
    query Products {
        products {
            id
            name
        }
    }
`;

export const RELATIONSHIP_OPTIONS_QUERY = gql`
    query RelationshipOptions {
        relationshipOptions
    }
`;

export const INSURANCE_CLAIM_TYPES_QUERY = gql`
    query InsuranceClaimTypes {
        insuranceClaimTypes
    }
`;

export const FAILURE_OPTIONS_QUERY = gql`
    query FailureOptions {
        failureOptions
    }
`;

export const IMPROVEMENT_OPTIONS_QUERY = gql`
    query ImprovementOptions {
        improvementOptions
    }
`;

export const FEEDBACK_OPTIONS_QUERY = gql`
    query FeedbackOptions(
        $filters: FeedbackOptionInput
        $searchString: String
    ) {
        feedbackOptions(
            filters: $filters
            searchString: $searchString
        ) {
            id
            name
        }
    }
`;

export const SCORING_CRITERIA_QUERY = gql`
    query GetScoringCriteria {
        scoringCriteria {
            id
            name
            category
            feedbackOption {
                id
                name
            }
        }
    }
`;

export const REASONS_QUERY = gql`
    query ScreenshotReasonOptions {
        screenshotReasonOptions {
            id
            name
        }
    }
`;

export const HELP_TEXTS_QUERY = gql`
    query HelpTexts {
        helpTexts
    }
`;

export const QUERY_CATEGORY = gql`
    query Category($categoryId: ID!) {
        category(categoryId: $categoryId) {
            id
            name
            networks {
                id
                name
                networkType
                position
            }
        }
    }
`;

export const QUERY_CASE_NETWORK_BY_CASE_ID = gql`
    ${CASE_NETWORK_INDEX_FIELDS}
    query CaseNetworkByCaseId($id: ID!) {
        caseNetworks: caseNetworkByCaseId(id: $id) {
            ...CaseNetworkIndexFields
        }
        case(id: $id) {
            id
            name
        }
    }
`;

export const CASE_NETWORKS_QUERY = gql`
    query CaseNetworks($id: ID!) {
        caseNetworks: caseNetworkByCaseId(id: $id) {
            id
            displayName
            summary
            categoryId
            isDirty
        }
    }
`;

export const NETWORKS_QUERY = gql`
    query GetNetworks {
        networks {
            id
            name
            categoryName
        }
    }
`;

export const CATEGORIES_QUERY = gql`
    query GetCategories {
        categories {
            id
            name
        }
    }
`;

export const FEEDBACKS_QUERY = gql`
    ${FEEDBACK_FIELDS}
    query Feedbacks(
        $caseId: ID!,
        $section: String,
        $sectionId: ID,
        $isClientInquiry: Boolean
    ) {
        feedbacks(
            caseId: $caseId,
            section: $section,
            sectionId: $sectionId,
            isClientInquiry: $isClientInquiry
        ) {
            ...FeedbackFields
        }
    }
`;

export const ACTION_FEEDBACKS_QUERY = gql`
    ${FEEDBACK_FIELDS_V2}
    query ActionFeedbacks(
        $caseId: ID!
    ) {
        feedbacks: actionFeedbacks(
            caseId: $caseId
        ) {
            ...FeedbackFields
        }
    }
`;

export const QC_REVIEW_QUERY = gql`
    ${SCREENSHOT_FIELDS}
    query CaseReview($id: ID!) {
        case(id: $id) {
            id
            name
            profileScreenshotsCount
            networkScreenshotsCount
            isFindingsEnabled
            screenshotsFileSize
            caseNetworks {
                id
                displayName
                matchResult
                presence
                link
                summary
                isDirty
                categoryId
                screenshotsFileSize
                screenshots {
                    ...ScreenshotFields
                }
            }
            feedbacks {
                id
                sectionId
                verdict
                isUnresolved
                isResolved
            }
            categories {
                id
                name
            }
        }
    }
`;

export const WORK_SESSIONS_QUERY = gql`
    ${WORK_SESSION_FIELDS}
    query WorkSessions(
        $caseId: ID!
    ) {
        workSessions(
            caseId: $caseId
        ) {
            ...WorkSessionFields
        }
    }
`;

export const FINDINGS_CATEGORIES_QUERY = gql`
    query FindingsCategories(
        $caseId: ID!
    ) {
        findingsCategories(
            caseId: $caseId
        ) {
            id
            name
        }
    }
`;

export const CASE_FINDINGS_QUERY = gql`
    query CaseFindings(
        $id: ID!
    ) {
        case(
            id: $id
        ) {
            id
            name
            isFindingsEnabled
            findingsCategories {
                id
                name
            }
            networkScreenshots {
                id
                externalLink
                findingsCategories {
                    id
                }
            }
        }
    }
`;

export const CONVERSATIONS_QUERY = gql`
    ${CASE_CHAT_FIELDS}
    ${AGENT_LOG_FIELDS}
    query Conversations(
        $caseId: ID!
    ) {
        caseChats(
            caseId: $caseId
        ) {
            ...CaseChatFields
        }
        agentLogs(
            caseId: $caseId
        ) {
            ...AgentLogFields
        }
    }
`;

export const USERS_QUERY = gql`
    query Users {
        users {
            id
            fullname
            email
            nameInitials
            profileColor
            smallProfilePic
            roleCd
            isFirstLayer
        }
    }
`;